@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/rene-bieder-campton-extralight.otf') format('opentype');
}

@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/rene-bieder-campton-book.otf') format('opentype');
}

@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/rene-bieder-campton-medium.otf') format('opentype');
}

@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/rene-bieder-campton-bold.otf') format('opentype');
}
