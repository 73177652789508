@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.react-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.react-player > div {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.CookieDeclarationType {
  word-break: break-word;
  -ms-word-break: break-all;
}

.CookieDeclaration {
  word-break: break-word;
  -ms-word-break: break-all;
}

.richtext a {
  word-wrap: anywhere;
}

.select-with-arrow > .select-arrow {
  position: relative;
  top: 17px;
  right: 30px;
}
